.product-query {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .product-query.centered {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
  }
  
  .search-input {
    margin-bottom: 20px;
  }
  
  .search-input input {
    text-transform: uppercase;
  }
  
  .loading-spinner {
    display: block;
    text-align: center;
    margin-top: 20px;
  }
  
  .result-card {
    margin-top: 20px;
  }
  
  .label-value-row {
    margin-bottom: 8px;
  }
  
  .label-tag {
    width: 100%;
    text-align: right;
    padding: 0 8px;
  }
  
  .value-tag {
    width: 100%;
  }
  
  .materials-title {
    margin-top: 24px;
  }
  
  .keyword-search {
    margin-bottom: 16px;
  }
  
  .materials-table {
    overflow-x: auto;
  }
  
  .copyable {
    cursor: pointer;
  }
  
  .copyable:hover {
    text-decoration: underline;
  }
  
  @media (max-width: 576px) {
    .product-query {
      padding: 10px;
    }
  
    .label-tag, .value-tag {
      text-align: left;
    }
  }
  .custom-table-row {
    height: auto !important;
    max-height: none !important;
  }
  .ant-typography,
.ant-btn,
.ant-input,
.ant-table {
    font-family: 'JZFSSans-Regular', 'JZFSSans-Medium', sans-serif !important;
  }
  .custom-table-row td {
    white-space: normal !important;
    height: auto !important;
    padding-top: 12px !important;
    padding-bottom: 12px !important;
  }