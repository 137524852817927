@font-face {
    font-family: 'JZFSSans-Medium';
    src: url('./fonts/JZFSSans-Medium.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'JZFSSans-Regular';
    src: url('./fonts/JZFSSans-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }